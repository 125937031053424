<template>
    <perfect-scrollbar
        tag="div"
        class="companies__list"
        v-if="otherDomains && otherDomains.length > 0"
    >
        <company
            v-for="domain in otherDomains"
            :key="domain.id"
            :domain="domain"
            class="companies__item"
        />
    </perfect-scrollbar>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "List",

        components: {
            Company : () => import('./Company')
        },

        computed: {
            ...mapGetters('sidebar', ['otherDomains'])
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .companies {
        $block-name: &;

        &__list {
            max-height: 200px;
            margin-bottom: 15px;
            overflow-y: scroll;
        }

        &__item {
            &.company {
                padding: 0px;
                opacity: 0.5;
                transition: opacity $ease;
                &:hover {
                    opacity: 1;
                }
            }

            & + #{$block-name}__item {
                margin-top: 10px;
            }
        }
    }
</style>
